import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'
import { memo, useCallback, useMemo } from 'react'
import { sqeezeIntoArray } from '../../../utils/functions/miscellaneous'
import CarouselCard from './carouselCard'
import CarouselAdCard from './carouselAdCard'
import GlideSlider from '../../common/glideSlider'
import { HOMEPAGE_SLIDER_SETTINGS } from '../../../constants'
import { useUpdateNamedCtx } from '../../../utils/contexts/pageContext'
import hasSpecificTag from '../../../utils/functions/hasSpecificTag'
import styles from './carousel.module.scss'

/**
 * Carousel Component.
 *
 * @param {Object} items props carousel items data.
 *
 * @returns {JSX.Element}
 */
function Carousel({ classNames, showSticky = true, contextName, items = [] }) {
  const { updateStore } = useUpdateNamedCtx(contextName)
  const defaultGamProps = { isLoaded: false, rawAdData: {}, slideData: {} }
  const gamPos = [
    { slotName: 'gam_feattile_pos1', index: 0, posIndex: 1, ...defaultGamProps },
    { slotName: 'gam_feattile_pos2', index: 1, posIndex: 3, ...defaultGamProps },
    { slotName: 'gam_feattile_pos3', index: 2, posIndex: 5, ...defaultGamProps }
  ]
  let newItems = items

  if (!isEmpty(items)) {
    gamPos.forEach((item) => {
      newItems = sqeezeIntoArray(newItems, item?.posIndex ?? 1, {
        type: 'adArticleCard',
        slotName: item?.slotName ?? '',
        ...item
      })
    })
  }

  const mountHandle = useCallback(
    (glide) => {
      updateStore && updateStore('glide', glide)
    },
    [updateStore]
  )

  const RenderCard = (item) => {
    const { id, isExternalURL = false, tags, type, thisSlideIndex, showSticky } = item
    const isArticleIncludesVideo = hasSpecificTag(tags?.nodes ?? [], 'video')
    const imageProps = {
      ...(item?.image ?? {}),
      fetchpriority: thisSlideIndex < 2 ? 'high' : 'low',
      loading: thisSlideIndex < 2 ? 'eager' : 'lazy'
    }
    const adCardProps = {
      item: item,
      isArticleIncludesVideo: isArticleIncludesVideo,
      showSticky: showSticky,
      isExternalURL,
      thisSlideIndex: thisSlideIndex
    }

    return type === 'adArticleCard' ? (
      <CarouselAdCard data={adCardProps} contextName={contextName} />
    ) : (
      <div key={`carouselcard-${id ?? thisSlideIndex}`} className='w-full'>
        <CarouselCard
          data={{
            ...item,
            isExternalURL,
            isArticleIncludesVideo,
            showSticky,
            index: thisSlideIndex,
            image: imageProps
          }}
        />
      </div>
    )
  }

  const featuredCarouselItems = useMemo(() => newItems, [newItems])
  return (
    <div className={styles['drive-home-carousel__wrapper']}>
      <h2 className='sr-only'>Featured Stories</h2>
      <GlideSlider
        afterMountCallBack={mountHandle}
        glideOptions={HOMEPAGE_SLIDER_SETTINGS}
        classNames={{
          mainWrapper: 'homepage-slider',
          slidesWrapper: 'lg:max-h-[427px]',
          navigation: {
            arrows: {
              prev: 'drive-home-carousel__arrow',
              next: 'drive-home-carousel__arrow'
            }
          }
        }}
      >
        {featuredCarouselItems.map((item, thisSlideIndex) => {
          const { id } = item
          const props = { ...item, thisSlideIndex, showSticky }
          return <RenderCard key={`carouselcardwrapper-${id ?? thisSlideIndex}`} {...props} />
        })}
      </GlideSlider>
    </div>
  )
}

Carousel.propTypes = {
  showSticky: PropTypes.bool,
  classNames: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      image: PropTypes.object.isRequired,
      url: PropTypes.string.isRequired,
      label: PropTypes.string,
      sticky: PropTypes.string
    })
  )
}

export default memo(Carousel)
