import cx from 'classnames'
import Image from '../../../common/image'
import Link, { linkDefaultProps } from '../../../common/link'
import { HomeCarouselPlayIcon } from '../../../icons'
import { sanitize } from '../../../../utils/functions/miscellaneous'
import styles from './../carousel.module.scss'
import { memo } from 'react'
import { FunctionComponent, useRef, useState } from 'react'
import { hasEllipsis } from '../../../../utils/functions/hasEllipsis'
import { ArrowForwardIcon } from '@grille/components/icons'
import { Color } from '@grille/constants/colors'

const labelMap = {
  news: 'News',
  reviews: 'Review',
  caradvice: 'Car Advice',
  dcoty: 'Car of the Year',
  image: 'Partner Content'
}

const CarouselCard: FunctionComponent<Props> = ({ data }) => {
  const {
    image,
    title,
    url,
    isExternalURL = false,
    isArticleIncludesVideo,
    index,
    type,
    content
  } = data
  const headlineRef = useRef<HTMLSpanElement>(null)
  const sanitizedTitle: string = sanitize(title)
  const [showHoverTitle, setShowHoverTitle] = useState<boolean>(false)
  const onHover = (): void => {
    const show: boolean = hasEllipsis(headlineRef.current, true)
    if (show !== showHoverTitle) {
      setShowHoverTitle(show)
    }
  }
  let loading = image?.loading

  if (!loading) {
    loading = index === 0 ? 'eager' : 'lazy'
  }

  const label = (labelMap?.[type as keyof typeof labelMap] as keyof typeof labelMap) ?? undefined

  return (
    <Link {...linkDefaultProps} href={url} isExternal={isExternalURL}>
      <a className={styles['drive-home-carousel__card-wrapper']}>
        <figure className={cx(styles['drive-home-carousel__img-wrapper'])}>
          <Image
            {...image}
            loading={loading}
            width='640'
            height='360'
            sizeName='HOMEPAGE_CAROUSEL_V2'
            alt={sanitizedTitle}
            altText={sanitizedTitle}
          />
          {isArticleIncludesVideo && (
            <span className={cx(styles['drive-home-carousel__play-btn-wrapper'])}>
              <HomeCarouselPlayIcon
                height='50'
                width='50'
                className={cx(styles['drive-home-carousel__play-icon'])}
              />
            </span>
          )}
          {/* not boolean value so use ternary operator. Otherwise it will render 0 when the label is 0 */}
          {label ? (
            <span className={cx(styles['drive-home-carousel__review-label'])}>{label}</span>
          ) : null}
        </figure>
        <div className={cx(styles['drive-home-carousel__link-wrapper'])}>
          {/* not boolean value so use ternary operator. Otherwise it will render 0 when the label is 0 */}
          {label ? (
            <span className={cx(styles['drive-home-carousel__review-tag'])}>{label}</span>
          ) : null}

          <div className={styles['drive-home-carousel__text-line-wrapper']}>
            <h3
              onMouseOver={onHover}
              // @ts-ignore
              ref={headlineRef}
              className={cx(styles['drive-home-carousel__link'])}
              dangerouslySetInnerHTML={{ __html: sanitizedTitle }}
            />
            {content && <div className={styles['drive-home-carousel__content']}>{content}</div>}
            <div className={styles['drive-home-carousel__read-now-wrapper']}>
              <div className={styles['drive-home-carousel__read-now']}>Read Now</div>
              <div className={styles['drive-home-carousel__read-now-arrow']}>
                <ArrowForwardIcon color={Color.blueDark} />
              </div>
            </div>
          </div>
        </div>
      </a>
    </Link>
  )
}

type Props = {
  data: {
    image: {
      [key: string]: any
    }
    type?: string
    title: string
    url: string
    sticky?: string
    isExternalURL?: boolean
    isArticleIncludesVideo: boolean
    showSticky: boolean
    index?: number
    content?: string
  }
}

export default memo(CarouselCard)
