import isEmpty from 'lodash/isEmpty'
import { FunctionComponent, memo, useEffect, useState } from 'react'
import CarouselCard from '../carouselCard'
import AdLoadingCard from './../adLoadingCard'
import AdTagless from '../../../../components/common/ad-units/adTagless'
import { useNamedPageContext } from '../../../../utils/contexts/pageContext'
import { AdUnitImageType, AdUnitWPType, CarouselAdCardProps } from './types'
import { MountedGlide } from '@grille/components/common/glideSlider'
import { getCarouselAdUnitImageType, getCarouselAdUnitWPType } from './helpers'

/**
 * Carousel Ad Component - fetches ad and calls a callback whenever thats done, parent decides to remove it if ad isnt served
 *
 * @param {Object} props.data comes from parent - doesnt include article data
 * @param {Function} props.sliderCallBack is called once ad has been processed
 *
 */
const CarouselAdCard: FunctionComponent<CarouselAdCardProps> = ({
  data: cardData,
  contextName
}) => {
  const { store } = useNamedPageContext(contextName) as Record<string, any>
  const { glide }: { glide: MountedGlide } = store || {}
  const { isArticleIncludesVideo, showSticky, item, thisSlideIndex } = cardData
  const [article, setArticle] = useState<null | {
    article: AdUnitImageType | AdUnitWPType
    fetched: boolean
  }>(null)

  // Load the carousel ad units from GAM & pull post data as requried.
  useEffect(() => {
    ;(async () => {
      const gamData = item
      if (gamData.index === -1) {
        return
      }
      const taglessData = await AdTagless({ id: item.slotName, endpoint: null })
      if (taglessData.data?.postType === 'image') {
        try {
          const res = getCarouselAdUnitImageType(taglessData.data)
          return setArticle({ article: res, fetched: true })
        } catch (e) {
          // eslint-disable-next-line
          console.error(`getCarouselAdUnit - Error - ${e}`)
        }
      } else {
        try {
          const res = await getCarouselAdUnitWPType(taglessData.data)
          return setArticle({ article: res, fetched: true })
        } catch (e) {
          // eslint-disable-next-line
          console.error(`getCarouselAdUnit - Error - ${e}`)
        }
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (article?.fetched) {
      glide.update({})
    }
  }, [article?.fetched, glide])

  if (!article?.article) {
    if (article?.fetched) {
      return null
    } else {
      return <AdLoadingCard />
    }
  }

  const adArticle = article.article

  return (
    <div className='w-full'>
      <CarouselCard
        data={{
          image: {
            ...adArticle?.image,
            fetchpriority: thisSlideIndex === 1 ? 'high' : 'low',
            loading: thisSlideIndex === 1 ? 'eager' : 'lazy'
          },
          type: adArticle?.type,
          title: adArticle?.title,
          url: adArticle?.url,
          isArticleIncludesVideo,
          showSticky,
          isExternalURL: adArticle?.isExternalURL
        }}
      />
      {!isEmpty(adArticle?.impressionTrackingUrls) &&
        adArticle?.impressionTrackingUrls?.map((trackingUrl, index) => {
          return (
            <img
              src={trackingUrl}
              width='1'
              height='1'
              className='gam-impression-tracker'
              key={index}
              data-component='carousel'
            />
          )
        })}
    </div>
  )
}

export default memo(CarouselAdCard)
