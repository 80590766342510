import { FunctionComponent, ReactElement, useState } from 'react'
import { useQuery } from '@apollo/client'
import { ListingMakesData, Make, ShowroomMakesData } from './../../types/findACar'
import cx from 'classnames'
import styles from './findACar.module.scss'
import { GET_TP_ALL_CURRENT_RELEASE_MAKES } from '../../queries/showrooms/makes/get-all-current-release-makes'
import { GET_LISTING_MAKES_AVAILABLE } from '../../queries/cars-for-sale/listings/get-cars-for-sale-makes'
import InputAndSearchFragment from './inputAndSearchFragment'
import FindACarBtnRow from './findACarBtnRow'
import { useFindACarPopularMakes } from '../../utils/hooks/useFindACarPopularMakes'
import { isTestEnv } from '../../utils/functions/isTestEnv'
import { usePageTakeOver } from '@grille/utils/hooks/usePageTakeOver'
const isTest = isTestEnv()
/**
 * Component for find a car search
 * @param inputIdPrefix prefix used for input ids, which is for SSR implementation
 * @param isMobile whether to apply mobile layout
 * @param isSSR whether to enable SSR logic
 */
const FindACar: FunctionComponent<FindACarProps> = ({ inputIdPrefix, isMobile, isSSR = false }) => {
  const [selectedBtn, setSelectedBtn] = useState<string>('')
  const isPageTakeOver = usePageTakeOver()
  //ssr data already fetched from appPage
  const { data: showroomMakesData } = useQuery<ShowroomMakesData>(
    GET_TP_ALL_CURRENT_RELEASE_MAKES,
    { context: { tailpipe: true } }
  )

  const { data: listingMakeData } = useQuery<ListingMakesData>(GET_LISTING_MAKES_AVAILABLE, {
    context: { listing: true }
  })
  const popularMakes: { showroom: Make[]; listing: Make[] } = useFindACarPopularMakes({
    listingMakeData,
    showroomMakesData
  })

  //cfs listing makes has no uuid from response, so use slug as value. Other fields will be fetched when needed
  const listingMakes: Make[] = (listingMakeData?.availableMakes?.options ?? []).map(
    ({ name, slug }) => ({ label: name, value: slug, slug: slug })
  )
  //ssr support, label and radio css selection
  const radioIds = {
    research: `${inputIdPrefix}-research-a-car`,
    buy: `${inputIdPrefix}-buy-a-car`
  }
  const inputRadioName: string = `${inputIdPrefix}-find-a-car-radio`

  const showroomSearchFragment: ReactElement = InputAndSearchFragment({
    id: radioIds.research,
    allMakes: showroomMakesData?.makes ?? [],
    popularMakes: popularMakes?.showroom ?? [],
    searchType: 'showroom',
    inputRadioName: inputRadioName,
    isSSR: isSSR
  })
  const cfsSearchFragment: ReactElement = InputAndSearchFragment({
    id: radioIds.buy,
    allMakes: listingMakes,
    popularMakes: popularMakes?.listing ?? [],
    searchType: 'car-for-sale',
    inputRadioName: inputRadioName,
    isSSR: isSSR
  })

  return (
    <div
      className={cx(styles['d-find-a-car'], 'bg-blue-darkest-screen', {
        [styles['d-find-a-car--is-page-take-over']]: isPageTakeOver
      })}
      data-testid={isTest ? 'find-a-car' : undefined}
    >
      <fieldset className={styles['d-find-a-car__wrapper']}>
        <legend className={cx(styles['d-find-a-car__legend'])}>
          What can we help you with today?
        </legend>

        {/*FindACarBtnRow takes in cfs and showroom search fragments inside for mobile layout */}
        <FindACarBtnRow
          isMobile={isMobile}
          isSSR={isSSR}
          setSelectedBtn={setSelectedBtn}
          selectedBtn={selectedBtn}
          radioIds={radioIds}
          showroomSearchFragment={showroomSearchFragment}
          cfsSearchFragment={cfsSearchFragment}
        />

        {!isMobile ? ( //desktop layout
          <>
            {/*always render if ssr, only render selected in browser*/}
            {isSSR || selectedBtn === radioIds.research ? showroomSearchFragment : null}
            {isSSR || selectedBtn === radioIds.buy ? cfsSearchFragment : null}
          </>
        ) : null}
      </fieldset>
    </div>
  )
}

type FindACarProps = {
  inputIdPrefix: string
  isMobile: boolean
  isSSR: boolean
}

export default FindACar
