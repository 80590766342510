import { AdTaglessImageType } from './types'
import { getClient } from '@grille/lib/client'

import {
  GET_HOME_SLIDE_CAR_ADVICE,
  GET_HOME_SLIDE_NEWS,
  GET_HOME_SLIDE_REVIEW
} from '@grille/queries/home/get-slide'

const { query } = getClient()

export const getCarouselAdUnitImageType = (rawAdData?: AdTaglessImageType) => {
  if (!rawAdData) return null
  const title = rawAdData.caption
  return {
    type: rawAdData.postType,
    id: rawAdData.requestId,
    title: title,
    image: {
      isExternal: true,
      title: title,
      altText: title,
      sourceUrl: rawAdData.images?.desktop
    },
    mobileImage: rawAdData.images?.mobile,
    url: rawAdData.clickthroughUrl,
    isExternalURL: true,
    impressionTrackingUrls: rawAdData.impressionTrackingUrls
  }
}

const queryMap = {
  news: GET_HOME_SLIDE_NEWS,
  review: GET_HOME_SLIDE_REVIEW,
  caradvice: GET_HOME_SLIDE_CAR_ADVICE
} as const

export const getCarouselAdUnitWPType = async (rawAdData: {
  postType?: string
  postIdentifier?: string
  requestId?: string
  clickthroughUrl?: string
  impressionTrackingUrls?: string[]
}) => {
  if (!rawAdData?.postType) {
    return null
  }
  const postType = rawAdData?.postType?.toLowerCase() ?? ''
  const queryDocument = queryMap[postType as keyof typeof queryMap]
  if (!queryDocument) return null
  const { data, error } = await query({
    query: queryDocument,
    variables: {
      uri: rawAdData?.postIdentifier ?? ''
    }
  })
  if (error) {
    // eslint-disable-next-line no-console
    console.log(`Error fetching carousel ad unit: ${error}`)
    return null
  }
  const resData = data?.[postType]
  const featuredImage = data?.[postType]?.featuredImage?.node
  if (resData && featuredImage) {
    return {
      type: rawAdData.postType,
      id: rawAdData.requestId,
      title: resData.title,
      image: featuredImage,
      url: rawAdData.clickthroughUrl ?? resData.uri,
      isExternalURL: !!rawAdData.clickthroughUrl,
      impressionTrackingUrls: rawAdData.impressionTrackingUrls
    }
  }
  return null
}
